import React from 'react';

export default function VideoLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 110 110">
  <g id="Group_6968" data-name="Group 6968" transform="translate(-601 -1504)">
    <rect id="Rectangle_2893" data-name="Rectangle 2893" width="110" height="110" rx="22" transform="translate(601 1504)" fill="#fff"/>
    <g id="Group_6967" data-name="Group 6967" transform="translate(-119.713 -174.983)">
      <circle id="Ellipse_25" data-name="Ellipse 25" cx="9.5" cy="9.5" r="9.5" transform="translate(803 1746)" fill="#34a0dc"/>
      <circle id="Ellipse_31" data-name="Ellipse 31" cx="9.5" cy="9.5" r="9.5" transform="matrix(0.035, 0.999, -0.999, 0.035, 748.701, 1745.393)" fill="#1d50a1"/>
      <path id="Path_8099" data-name="Path 8099" d="M9.5,0A9.5,9.5,0,0,1,19,9.5v23a9.5,9.5,0,0,1-19,0L-.076,13.477.26,11.827c0-.472-.515-1.2-.4-1.957C.484,5.588,4.725,0,9.5,0Z" transform="matrix(0.788, -0.616, 0.616, 0.788, 747.179, 1732.811)" fill="#1d50a1"/>
      <rect id="Rectangle_2882" data-name="Rectangle 2882" width="19" height="41" rx="9.5" transform="translate(767.631 1713.94) rotate(-39)" fill="#34a0dc"/>
      <path id="Path_8115" data-name="Path 8115" d="M.2.574l4.074.815a6.56,6.56,0,0,0-2.032,3.6c-.328,2.155.007,2.545.368,4.762a4.941,4.941,0,0,0,2.24,3.278l-7.688.357s2.126-.97,2.66-2.525,1.322-4.227.29-6.558A5.556,5.556,0,0,0-2.8,1.322Z" transform="matrix(0.809, -0.588, 0.588, 0.809, 798.32, 1738.864)" fill="#34a0dc"/>
      <path id="Path_8116" data-name="Path 8116" d="M.2.574l4.074.815a6.56,6.56,0,0,0-2.032,3.6c-.328,2.155.007,2.545.368,4.762a4.941,4.941,0,0,0,2.24,3.278l-7.688.357s2.126-.97,2.66-2.525,1.322-4.227.29-6.558A5.556,5.556,0,0,0-2.8,1.322Z" transform="matrix(0.616, 0.788, -0.788, 0.616, 755.67, 1740.467)" fill="#1d50a1"/>
    </g>
  </g>
</svg>
  );
}
